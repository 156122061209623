import React, { lazy, Suspense } from 'react';
import { Routes, Route, } from "react-router-dom";
import { PrivatesRoutes } from './PrivatesRoutes';
import { PublicRoutes } from './PublicRoutes';

import { SocketProvider } from '../context/SocketContext';
const Home = lazy(() => import("../pages/Home"));
// const Signup = lazy(() => import("../pages/Signup"));
const Login = lazy(() => import("../pages/Login"));
const Subscribe = lazy(() => import("../pages/Subscribe"));
const Rooms = lazy(() => import("../pages/Rooms"))


const AllRoutes = () => {
    return (
        <Suspense fallback={<div>loading...</div>}>
            <Routes>
                <Route element={<PrivatesRoutes />}>
                    <Route path="/home" element={<Home />} />
                    <Route path="/rooms" element={<SocketProvider><Rooms /></SocketProvider>} />
                    </Route>

                    <Route element={<PublicRoutes />}>
                     <Route path="/login" element={<Login />} ></Route>
                    {/* <Route path="/signup" element={<Signup />} ></Route> */}
                    <Route path="/" element={<Subscribe />} />   
                    </Route>
                   
            </Routes>
        </Suspense>
    )
}

export default AllRoutes